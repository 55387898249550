import gql from 'graphql-tag';
import {globalfunc} from '../../../../shared/GlobalFunction.js';

class CurrencyService {
    readQuery(){
        var query = `query{
            GetCurrency{
                id
                currency_code
                currency_name
                currency_symbol
                created_at
                last_update
            }
        }`;
        return query;
    }

    async getCurrencyById(id){        
        const variables = {
            id : id
        }
        var query = gql`query($id:Int) {
            GetCurrency(Id: $id) {
                id
                currency_code
                currency_name
                currency_symbol
            }
        }`;
        var result = await globalfunc.defaultApolloQueryDefault(query, variables);
        
        return result.data.GetCurrency[0];
    }

    async deleteQuery(variables){
        const query = gql`mutation($id:Int!){
            DeleteCurrency(ID:$id)
        }`;
        return await globalfunc.defaultApolloMutationDefault(query, variables)
    }

    async addQuery(variables){
        const query = gql`mutation($data:NewCurrency!){
            CreateCurrency(New:$data)
        }`;

        return await globalfunc.defaultApolloMutationDefault(query, variables)
    }
    
    async updateQuery(variables){
        const query = gql`mutation($id:Int!,$data:NewCurrency!){
            UpdateCurrency(ID:$id,New:$data)
        }`;

        return await globalfunc.defaultApolloMutationDefault(query, variables)
    }
}


export default new CurrencyService();